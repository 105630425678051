import { CampaignLink, CampaignLinkBase } from 'common/types/CampaignLink';
import { CohortWalletInfo, WalletInfo } from 'common/types/WalletInfo';
import { CustomizedCategory, Growing3Category } from 'common/types/Category';
import { FeatureLimit, FeatureUsage } from 'common/types/FeatureLimit';
import {
  InfluencerPublishResult,
  TwitterAdsAccount,
  TwitterAdsAdGroup,
  TwitterAdsCampaign,
  TwitterAuth,
} from 'common/types/TwitterAds';
import { LevelCount, TwitterAccount } from 'common/types/TwitterAccount';
import { TwitterAdGroupPublishRecord, TwitterAudiencePublishRecord } from 'common/types/TwitterPublishRecord';
import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';

import { AddressMetric } from 'common/types/AddressMetrics';
import { AttributionSource } from 'common/types/AttributionSource';
import { Audience } from 'common/types/Audience';
import { BehaviorSubject } from 'rxjs';
import { Changelog } from 'common/types/Changelog';
import { Cohort } from 'common/types/Cohort';
import { CustomizedInfluencerJob } from './types/CustomizedInfluencer';
import { DefiProject } from 'common/types/DefiProject';
import { GAAccount } from 'common/types/GoogleAnalytics';
import { GoogleSheet } from 'common/types/GoogleDrive';
import { IntegrationAuth } from 'common/types/IntegrationAuth';
import { PaginationOptions } from 'components/table/CommonTable';
import { Tutorial } from 'common/types/Tutorial';
import { Uris } from 'Uris';
import { User } from 'common/types/User';
import { UserSurvey } from 'common/types/UserSurvey';
import { WalletAsset } from 'common/types/WalletAsset';
import { get } from 'lodash';
import pako from 'pako';
import { redirectToExternalPage } from 'common/utils';

interface ApiResponse<T> {
  status?: string;
  data?: T;
  message?: string;
  total_rows?: number;
  return_rows?: number;
}

class ClientAPI {
  static isBlock$: BehaviorSubject<boolean> | undefined = undefined;
  static async checkFetchResponse(res: Response): Promise<any | void> {
    let msg = '';
    try {
      const resJson = await res.json();
      const resJsonDetail = get(resJson, 'detail');
      if (resJsonDetail) msg = resJsonDetail;
      else {
        return resJson;
      }
    } catch (err) {
      if (err instanceof SyntaxError) {
        msg = 'Unexpected errors happened, please contact administrator.';
      }
    } finally {
      // token expired -> need to re-login
      if (res.status === 426) {
        redirectToExternalPage(`${Uris.External.Login}?entry_point=console`);
        return undefined;
      }
      if (msg === '') {
        if (400 <= res.status && res.status < 500) {
          msg = `${res.status} Client Error: ${res.statusText} for url: ${res.url}`;
        } else if (500 <= res.status && res.status < 600) {
          // Server error
          msg = `${res.status} Server Error: ${res.statusText} for url: ${res.url}`;
        }
      }
      if (res.status === 423) {
        ClientAPI.isBlock$?.next(true);
      }

      if (msg !== '') {
        // send status code also for checking
        throw Error(msg, { cause: res.status });
      }
    }
  }

  static checkAxiosResponse(res: AxiosResponse<Response, any>) {
    let msg = '';
    try {
      const resJson = res.data;
      const resJsonDetail = get(resJson, 'detail');
      if (resJsonDetail) msg = resJsonDetail;
      else {
        return resJson;
      }
    } catch (err) {
      if (err instanceof SyntaxError) {
        msg = 'Unexpected errors happened, please contact administrator.';
      }
    } finally {
      if (msg === '') {
        if (400 <= res.status && res.status < 500) {
          msg = `${res.status} Client Error: ${res.statusText} for url: ${res.data.url}`;
        } else if (500 <= res.status && res.status < 600) {
          // Server error
          msg = `${res.status} Server Error: ${res.statusText} for url: ${res.data.url}`;
        }
      }

      if (msg !== '') {
        // send status code also for checking
        throw Error(msg, { cause: res.status });
      }
    }
  }

  static extractResponse<T>(resJson: any): ApiResponse<T> {
    return {
      status: get(resJson, 'status', undefined),
      data: get(resJson, 'data', undefined),
      message: get(resJson, 'message', undefined),
      total_rows: get(resJson, 'total_rows', undefined),
      return_rows: get(resJson, 'return_rows', undefined),
    };
  }

  static async gzipFetch(input: RequestInfo | URL, init?: RequestInit | undefined) {
    let blob: Blob | undefined = undefined;
    if (init?.body) {
      const compressedData = pako.gzip(init?.body as unknown as string);
      blob = new Blob([compressedData], { type: 'application/gzip' });
    }
    return await fetch(input, {
      ...init,
      headers: { ...init?.headers, 'Content-Encoding': 'gzip', 'Content-Type': 'application/json' },
      body: blob,
    });
  }

  public static async getActiveWalletCount() {
    const url = `${Uris.Api.CohortSetting.ActiveWalletCount}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<number>(resJson);
  }

  public static async getWalletAssets() {
    const url = `${Uris.Api.CohortSetting.WalletAssets}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<WalletAsset[]>(resJson);
  }

  public static async getDefiProjects() {
    const url = `${Uris.Api.CohortSetting.DefiProjects}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<DefiProject[]>(resJson);
  }

  public static async getCohorts() {
    const url = `${Uris.Api.Cohort.GetCohorts}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Cohort[]>(resJson);
  }

  public static async getCohort(corhortId: number) {
    const url = `${Uris.Api.Cohort.GetCohort.replace(':cohortId', corhortId.toString())}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Cohort>(resJson);
  }

  public static async createCohort(cohort: Cohort) {
    const url = `${Uris.Api.Cohort.CreateCohort}`;
    const res = await ClientAPI.gzipFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cohort),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Cohort>(resJson);
  }

  public static async updateCohort(cohort: Cohort) {
    if (!cohort.id) throw Error('update cohort failed, cohort id is require');
    const url = `${Uris.Api.Cohort.UpdateCohort.replace(':cohortId', cohort.id.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: cohort.name, description: cohort.description, scheduler: cohort.scheduler }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async deleteCohort(cohortId: number) {
    const url = `${Uris.Api.Cohort.DeleteCohort.replace(':cohortId', cohortId.toString())}`;
    const res = await fetch(url, {
      method: 'DELETE',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string | Audience[]>(resJson);
  }

  public static async createCohortJob(cohortId: number) {
    const url = `${Uris.Api.Cohort.CreateCohortJob.replace(':cohortId', cohortId.toString())}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cohortId }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Cohort>(resJson);
  }

  public static async getCohortWalletCount(cohort: Cohort) {
    const url = `${Uris.Api.Cohort.GetCohortWalletCount}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cohort),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<{ predict_number: number }>(resJson);
  }

  public static async getWalletList(corhortId: number, paginationOptions?: PaginationOptions) {
    const url = `${Uris.Api.Cohort.GetWallets.replace(':cohortId', corhortId.toString())}`;
    const params = paginationOptions ? `?rows=${paginationOptions.rowsPerPage}&page=${paginationOptions.page + 1}` : '';
    const res = await fetch(url + params, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CohortWalletInfo[]>(resJson);
  }

  public static async getWallet(address: string) {
    const url = `${Uris.Api.AddressMetrics.GetWalletBasicInfo.replace(':address', address)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<WalletInfo>(resJson);
  }

  public static async getCohortWalletDownloadLink(corhortId: number) {
    const url = `${Uris.Api.Cohort.GetWalletsDownloadLink.replace(':cohortId', corhortId.toString())}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async getAudiences() {
    const url = `${Uris.Api.Audience.GetAudiences}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Audience[]>(resJson);
  }

  public static async getAudience(audienceId: number) {
    const url = `${Uris.Api.Audience.GetAudience.replace(':audienceId', audienceId.toString())}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Audience>(resJson);
  }

  public static async createAudience(audience: Audience) {
    const url = `${Uris.Api.Audience.CreateAudience}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...audience, cohorts: audience.cohorts.map((c) => c.id) }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Cohort>(resJson);
  }

  public static async updateAudience(audience: Audience) {
    if (!audience.id) throw Error('update audience failed, audience id is require');
    const url = `${Uris.Api.Audience.UpdateAudience.replace(':audienceId', audience.id.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: audience.name, description: audience.description }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async deleteAudience(audienceId: number) {
    const url = `${Uris.Api.Audience.DeleteAudience.replace(':audienceId', audienceId.toString())}`;
    const res = await fetch(url, {
      method: 'DELETE',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async getTwitterAccounts(audienceId: number, paginationOptions?: PaginationOptions) {
    const url = `${Uris.Api.Audience.GetTwitterAccounts.replace(':audienceId', audienceId.toString())}`;
    const params = paginationOptions ? `?rows=${paginationOptions.rowsPerPage}&page=${paginationOptions.page + 1}` : '';
    const res = await fetch(url + params, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAccount[]>(resJson);
  }

  // return user profile
  public static async getUserProfile(): Promise<ApiResponse<User>> {
    const url = `${Uris.Api.User.Profile}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<User>(resJson);
  }

  public static async createUserSurvey(userSurvey: UserSurvey) {
    const url = `${Uris.Api.User.CreateSurvey}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...userSurvey }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  // public static async checkTelegramUsernameAvailable(username: string) {
  //   const url = `${Uris.Api.Telegram.UsernameAvailable}`.replace(':username', username);
  //   const res = await fetch(url, {
  //     method: 'GET',
  //   });
  //   const resJson = await ClientAPI.checkFetchResponse(res);
  //   return ClientAPI.extractResponse<UsernameCheckResult>(resJson);
  // }

  public static async subscribeFeature(email: string, pathname: string, component: string) {
    const url = `${Uris.Api.FeatureSubscription.CreateFeatureSubscription}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, pathname, component }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async getDefaultTwitterAuth() {
    const url = `${Uris.Api.TwitterAds.GetDefaultAuth}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAuth | null>(resJson);
  }

  public static async getTwitterAuths() {
    const url = `${Uris.Api.TwitterAds.GetAuths}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAuth[]>(resJson);
  }

  public static async deleteTwitterAuth(authId: string) {
    const url = `${Uris.Api.TwitterAds.DeleteAuth.replace(':authId', authId)}`;
    const res = await fetch(url, {
      method: 'DELETE',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async getDefaultTwitterAdsAccount() {
    const url = `${Uris.Api.TwitterAds.GetDefaultAccount}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAdsAccount | null>(resJson);
  }

  public static async getTwitterAdsAccounts() {
    const url = `${Uris.Api.TwitterAds.GetAccounts}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAdsAccount[]>(resJson);
  }

  public static async getTwitterAdsAccountsWithAuthId(authId: string) {
    const url = `${Uris.Api.TwitterAds.GetAccountsWithAuthId.replace(':authId', authId)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAdsAccount[]>(resJson);
  }

  public static async getTwitterAdsCampaigns(accountId?: string) {
    const url = `${Uris.Api.TwitterAds.GetCampaigns}`;
    const params = accountId ? `?account_id=${accountId}` : '';
    const res = await fetch(url + params, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAdsCampaign[]>(resJson);
  }

  public static async getTwitterAdsAdGroups(accountId?: string) {
    const url = `${Uris.Api.TwitterAds.GetAdGroups}`;
    const params = accountId ? `?account_id=${accountId}` : '';
    const res = await fetch(url + params, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAdsAdGroup[]>(resJson);
  }

  public static async updateDefaultTwitterAuthAccount(authId: string, accountId: string) {
    const url = `${Uris.Api.TwitterAds.UpdateDefaultAuthAccount}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ twitter_auth_id: authId, twitter_ads_account_id: accountId }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse(resJson);
  }

  public static async getAudienceLevelCounts(audienceId: number) {
    const url = `${Uris.Api.Audience.GetAudienceLevelCounts.replace(':audienceId', audienceId.toString())}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<LevelCount[]>(resJson);
  }

  public static async checkAudienceNameAvailable(accountId: string, audienceName: string) {
    const url = `${Uris.Api.TwitterAds.CheckAudienceNameAvailable}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account_id: accountId,
        audience_name: audienceName,
      }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<boolean>(resJson);
  }

  public static async publishAudiences(
    audienceId: number,
    accountId: string,
    audienceName: string,
    audienceDescription?: string,
  ) {
    const url = `${Uris.Api.TwitterAds.PublishAudiences}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        audience_id: audienceId,
        account_id: accountId,
        audience_name: audienceName,
        audience_description: audienceDescription,
      }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<boolean>(resJson);
  }

  public static async publishInfluencers(
    audienceId: number,
    levels: string[],
    accountId: string,
    adGroupIds: string[],
  ) {
    const url = `${Uris.Api.TwitterAds.PublishInflencers}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ audience_id: audienceId, levels, account_id: accountId, ad_group_ids: adGroupIds }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<InfluencerPublishResult[]>(resJson);
  }

  public static async getGrowing3Categories(): Promise<ApiResponse<Growing3Category[]>> {
    const url = `${Uris.Api.Cohort.Category.GetGrowing3Categories}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Growing3Category[]>(resJson);
  }

  public static async getCustomizedCategories(): Promise<ApiResponse<CustomizedCategory[]>> {
    const url = `${Uris.Api.Cohort.Category.GetCustomizedCategories}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CustomizedCategory[]>(resJson);
  }

  public static async createCustomizeCategory(
    formData: FormData,
    controller?: AbortController,
    onUploadProgress?: (e: AxiosProgressEvent) => void,
  ): Promise<ApiResponse<CustomizedCategory>> {
    const res = await axios.post<Response>(Uris.Api.Cohort.Category.CreateCustomizedCategory, formData, {
      signal: controller?.signal,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    });
    const resJson = ClientAPI.checkAxiosResponse(res);
    return ClientAPI.extractResponse<CustomizedCategory>(resJson);
  }

  public static async updateCustomizeCategory(
    categoryId: number,
    name: string,
    description?: string,
  ): Promise<ApiResponse<string>> {
    const url = `${Uris.Api.Cohort.Category.UpdateCustomizedCategory.replace(':categoryId', categoryId.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: name, description: description }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async deleteCustomizedCategory(categoryId: number): Promise<ApiResponse<string>> {
    const url = `${Uris.Api.Cohort.Category.UpdateCustomizedCategory.replace(':categoryId', categoryId.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ disabled: true }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async getCustomizedCategoriyFileLink(categoryId: number): Promise<ApiResponse<string>> {
    const url = `${Uris.Api.Cohort.Category.GetCustomizedCategoryFileLink.replace(
      ':categoryId',
      categoryId.toString(),
    )}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  // return redirect_uri
  public static async integrationOauth1(provider: string) {
    const url = `${Uris.Api.Integration.Oauth1.replace(':provider', provider)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  // return redirect_uri
  public static async integrationOauth2(provider: string) {
    const url = `${Uris.Api.Integration.Oauth2.replace(':provider', provider)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  // return redirect_uri
  public static async deleteIntegrationAuth(provider: string, authId: string) {
    const url = `${Uris.Api.Integration.DeleteAuth.replace(':provider', provider).replace(':authId', authId)}`;
    const res = await fetch(url, { method: 'DELETE' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<IntegrationAuth>(resJson);
  }

  public static async getDefaultIntegrationAuth(provider: string) {
    const url = `${Uris.Api.Integration.GetDefaultAuth.replace(':provider', provider)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<IntegrationAuth | null>(resJson);
  }

  public static async getIntegrationAuths(provider: string) {
    const url = `${Uris.Api.Integration.GetAuths.replace(':provider', provider)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<IntegrationAuth[]>(resJson);
  }

  public static async updateDefaultIntegrationAuth(provider: string, authId: string) {
    const url = `${Uris.Api.Integration.UpdateDefaultAuth.replace(':provider', provider)}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth_id: authId }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse(resJson);
  }

  public static async getAttributionSources(sourceType: string) {
    const url = `${Uris.Api.AttributionSource.GetAttributionSources.replace(':sourceType', sourceType)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AttributionSource[]>(resJson);
  }

  public static async createAttributionSources(
    sourceType: string,
    authId: string,
    propertyIds: string[],
    disabled: boolean,
  ) {
    const url = `${Uris.Api.AttributionSource.CreateAttributionSources.replace(':sourceType', sourceType)}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth_id: authId, property_ids: propertyIds, disabled: disabled }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AttributionSource[]>(resJson);
  }

  public static async updateAttributionSource(sourceType: string, sourceId: number, disabled: boolean) {
    const url = `${Uris.Api.AttributionSource.UpdateAttributionSource.replace(':sourceType', sourceType).replace(
      ':sourceId',
      sourceId.toString(),
    )}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ disabled: disabled }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AttributionSource>(resJson);
  }

  public static async deleteAttributionSource(sourceType: string, sourceId: number) {
    const url = `${Uris.Api.AttributionSource.DeleteAttributionSource.replace(':sourceType', sourceType).replace(
      ':sourceId',
      sourceId.toString(),
    )}`;
    const res = await fetch(url, {
      method: 'DELETE',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AttributionSource>(resJson);
  }

  public static async getGAAccounts(authId: string) {
    const url = `${Uris.Api.GoogleAnalytics.GetAccounts.replace(':authId', authId)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<GAAccount[]>(resJson);
  }

  public static async getTwitterAdGroupPublishRecords(audienceId?: number) {
    const url = `${Uris.Api.TwitterPublishRecord.GetTwitterAdGroupPublishRecords}${
      audienceId ? '?audience_id=' + audienceId : ''
    }`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAdGroupPublishRecord[]>(resJson);
  }

  public static async getTwitterAudiencePublishRecords(audienceId?: number) {
    const url = `${Uris.Api.TwitterPublishRecord.GetTwitterAudeincePublishRecords}${
      audienceId ? '?audience_id=' + audienceId : ''
    }`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<TwitterAudiencePublishRecord[]>(resJson);
  }

  public static async getCampaignLinks() {
    const url = `${Uris.Api.CampaignLink.GetCampaignLinks}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CampaignLink[]>(resJson);
  }

  public static async createCampaignLinks(campaignLinks: CampaignLinkBase[]) {
    const url = `${Uris.Api.CampaignLink.CreateCampaignLinks}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ campaign_links: campaignLinks }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CampaignLink[]>(resJson);
  }

  public static async updateCampaignLink(linkId: number, campaignLink: CampaignLinkBase) {
    const url = `${Uris.Api.CampaignLink.UpdateCampaignLink.replace(':linkId', linkId.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(campaignLink),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CampaignLink>(resJson);
  }

  public static async deleteCampaignLink(linkId: number) {
    const url = `${Uris.Api.CampaignLink.DeleteCampaignLink.replace(':linkId', linkId.toString())}`;
    const res = await fetch(url, {
      method: 'DELETE',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CampaignLink>(resJson);
  }

  public static async getCompletedTutorials() {
    const url = `${Uris.Api.Tutorial.GetCompletedTutorials}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Tutorial[]>(resJson);
  }

  public static async completeTutorial(tutorial: Tutorial) {
    const url = `${Uris.Api.Tutorial.CompletedTutorial.replace(':key', tutorial.key)}`;
    const res = await fetch(url, {
      method: 'POST',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string | undefined>(resJson);
  }

  public static async checkTweetAvailable(username: string, tweetId: string) {
    const url = `${Uris.Api.Tweet.Available.replace(':username', username).replace(':tweetId', tweetId)}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<boolean>(resJson);
  }

  public static async getCohortAddressMetrics(cohortId: number, metricName: string) {
    const url = `${Uris.Api.AddressMetrics.GetCohortAddressMetrics.replace(':cohortId', cohortId.toString()).replace(
      ':metricName',
      metricName,
    )}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AddressMetric[]>(resJson);
  }

  public static async getWalletTotalAsset(address: string) {
    const url = `${Uris.Api.AddressMetrics.GetWalletTotalAsset.replace(':address', address)}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AddressMetric[]>(resJson);
  }

  public static async getWalletTokenAsset(address: string, chain: string) {
    const url = `${Uris.Api.AddressMetrics.GetWalletTokenAsset.replace(':address', address).replace(':chain', chain)}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AddressMetric[]>(resJson);
  }

  public static async getWalletHistoryAsset(address: string, chain: string, days: number) {
    const url = `${Uris.Api.AddressMetrics.GetWalletHistoryAsset.replace(':address', address)
      .replace(':chain', chain)
      .replace(':days', days.toString())}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<AddressMetric[]>(resJson);
  }

  public static async getChangelogs() {
    const url = `${Uris.Api.Changelogs.GetChangelogs}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<Changelog | undefined>(resJson);
  }

  public static async exportCollectionToGoogleDrive(collectionId: number, sheetName: string, authId: string) {
    const url = `${Uris.Api.GoogleDrive.ExportCollection.replace(':collectionId', collectionId.toString())}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sheet_name: sheetName, auth_id: authId }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<GoogleSheet>(resJson);
  }

  public static async getInfluencerUploadJobs() {
    const url = `${Uris.Api.InfluencerUpload.GetInfluencerUploadJobs}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CustomizedInfluencerJob[]>(resJson);
  }

  public static async createInfluencerUploadJob(usernames: string[], name?: string, collection_id?: number) {
    const url = `${Uris.Api.InfluencerUpload.CreateInfluencerUploadJob}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ usernames, name, collection_id }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<CustomizedInfluencerJob>(resJson);
  }

  public static async getFeatureLimits() {
    const url = `${Uris.Api.FeatureLimits.GetFeatureLimits}`;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<FeatureLimit[]>(resJson);
  }

  public static async getUserFeatureUsage(
    featureName: string,
    subModule?: string,
    featureExtra?: Record<string, string>,
  ) {
    const url = `${Uris.Api.User.Feature.GetUsage}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feature_name: featureName, sub_module: subModule, feature_extra: featureExtra }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<FeatureUsage>(resJson);
  }
}

export default ClientAPI;
