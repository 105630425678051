import { Config } from 'config';

export const Uris = Object.seal({
  Api: {
    User: {
      Profile: `${Config.ApiServer}/users/profile`,
      CreateSurvey: `${Config.ApiServer}/users/survey`,
      Feature: {
        GetUsage: `${Config.ApiServer}/users/feature/usage`,
      },
    },
    Cohort: {
      GetCohort: `${Config.ApiServer}/cohorts/:cohortId`,
      GetCohorts: `${Config.ApiServer}/cohorts`,
      CreateCohort: `${Config.ApiServer}/cohorts`,
      CreateCohortJob: `${Config.ApiServer}/cohorts/:cohortId/cohort_jobs`,
      GetWallets: `${Config.ApiServer}/cohorts/:cohortId/wallets`,
      GetWalletsDownloadLink: `${Config.ApiServer}/cohorts/:cohortId/wallets/download`,
      UpdateCohort: `${Config.ApiServer}/cohorts/:cohortId`,
      DeleteCohort: `${Config.ApiServer}/cohorts/:cohortId`,
      GetCohortWalletCount: `${Config.ApiServer}/cohort_setting/predict`,
      Category: {
        GetCustomizedCategories: `${Config.ApiServer}/customized_categories`,
        GetGrowing3Categories: `${Config.ApiServer}/growing3_categories`,
        CreateCustomizedCategory: `${Config.ApiServer}/customized_categories`,
        UpdateCustomizedCategory: `${Config.ApiServer}/customized_categories/:categoryId`,
        GetCustomizedCategoryFileLink: `${Config.ApiServer}/customized_categories/:categoryId/file_link`,
      },
    },
    CohortSetting: {
      ActiveWalletCount: `${Config.ApiServer}/cohort_setting/active_wallet_count`,
      WalletAssets: `${Config.ApiServer}/cohort_setting/wallet_assets`,
      DefiProjects: `${Config.ApiServer}/cohort_setting/defi_projects`,
    },
    Audience: {
      GetAudience: `${Config.ApiServer}/audiences/:audienceId`,
      GetAudiences: `${Config.ApiServer}/audiences`,
      GetTwitterAccounts: `${Config.ApiServer}/audiences/:audienceId/twitter_accounts`,
      CreateAudience: `${Config.ApiServer}/audiences`,
      UpdateAudience: `${Config.ApiServer}/audiences/:audienceId`,
      DeleteAudience: `${Config.ApiServer}/audiences/:audienceId`,
      GetAudienceLevelCounts: `${Config.ApiServer}/audiences/:audienceId/level_counts`,
    },
    FeatureLimits: {
      GetFeatureLimits: `${Config.ApiServer}/features/limits`,
    },
    FeatureSubscription: {
      CreateFeatureSubscription: `${Config.ApiServer}/feature_subscription`,
    },
    TwitterAds: {
      GetDefaultAuth: `${Config.ApiServer}/twitter_ads/auths/default`,
      GetAuths: `${Config.ApiServer}/twitter_ads/auths`,
      DeleteAuth: `${Config.ApiServer}/twitter_ads/auths/:authId`,
      GetDefaultAccount: `${Config.ApiServer}/twitter_ads/accounts/default`,
      GetAccounts: `${Config.ApiServer}/twitter_ads/accounts`,
      GetAccountsWithAuthId: `${Config.ApiServer}/twitter_ads/:authId/accounts`,
      GetCampaigns: `${Config.ApiServer}/twitter_ads/campaigns`,
      GetAdGroups: `${Config.ApiServer}/twitter_ads/ad_groups`,
      UpdateDefaultAuthAccount: `${Config.ApiServer}/twitter_ads/accounts/default`,
      PublishAudiences: `${Config.ApiServer}/twitter_ads/audiences/publish`,
      PublishInflencers: `${Config.ApiServer}/twitter_ads/influencers/publish`,
      CheckAudienceNameAvailable: `${Config.ApiServer}/twitter_ads/audience/available`,
    },
    Integration: {
      Oauth1: `${Config.ApiServer}/integration/oauth_1/oauth/:provider`,
      Oauth2: `${Config.ApiServer}/integration/oauth_2/oauth/:provider`,
      GetDefaultAuth: `${Config.ApiServer}/integration/:provider/default`,
      GetAuths: `${Config.ApiServer}/integration/:provider`,
      UpdateDefaultAuth: `${Config.ApiServer}/integration/:provider/default`,
      DeleteAuth: `${Config.ApiServer}/integration/:provider/:authId`,
    },
    GoogleAnalytics: {
      GetAccounts: `${Config.ApiServer}/google_analytics/:authId/accounts`,
    },
    GoogleDrive: {
      ExportCollection: `${Config.ApiServer}/google_drive/collection/:collectionId/export`,
    },
    AttributionSource: {
      GetAttributionSources: `${Config.ApiServer}/attribution_sources/:sourceType`,
      CreateAttributionSources: `${Config.ApiServer}/attribution_sources/:sourceType`,
      UpdateAttributionSource: `${Config.ApiServer}/attribution_sources/:sourceType/:sourceId`,
      DeleteAttributionSource: `${Config.ApiServer}/attribution_sources/:sourceType/:sourceId`,
    },
    TwitterPublishRecord: {
      GetTwitterAdGroupPublishRecords: `${Config.ApiServer}/twitter_ad_group_publish_records`,
      GetTwitterAudeincePublishRecords: `${Config.ApiServer}/twitter_audience_publish_records`,
    },
    CampaignLink: {
      GetCampaignLinks: `${Config.ApiServer}/campaign_links`,
      CreateCampaignLinks: `${Config.ApiServer}/campaign_links/batch`,
      UpdateCampaignLink: `${Config.ApiServer}/campaign_links/:linkId`,
      DeleteCampaignLink: `${Config.ApiServer}/campaign_links/:linkId`,
    },
    // Telegram: {
    //   UsernameAvailable: `${Config.ApiServer}/telegram/:username/available`,
    // },
    Tutorial: {
      GetCompletedTutorials: `${Config.ApiServer}/user_tutorials`,
      CompletedTutorial: `${Config.ApiServer}/user_tutorials/:key`,
    },
    Tweet: {
      Available: `${Config.ApiServer}/twitter/tweet/:username/:tweetId/available`,
    },
    AddressMetrics: {
      GetCohortAddressMetrics: `${Config.ApiServer}/address_metrics/cohort/:cohortId/:metricName`,
      GetWalletBasicInfo: `${Config.ApiServer}/address_metrics/wallet/:address/basic_info`,
      GetWalletTotalAsset: `${Config.ApiServer}/address_metrics/wallet/:address/total_asset`,
      GetWalletTokenAsset: `${Config.ApiServer}/address_metrics/wallet/:address/token_asset/:chain`,
      GetWalletHistoryAsset: `${Config.ApiServer}/address_metrics/wallet/:address/history_asset/:chain/:days`,
    },
    Changelogs: {
      GetChangelogs: `${Config.ApiServer}/changelogs`,
    },
    InfluencerUpload: {
      GetInfluencerUploadJobs: `${Config.ApiServer}/influencer_upload/jobs`,
      CreateInfluencerUploadJob: `${Config.ApiServer}/influencer_upload/jobs`,
    },
  },
  ExtensionApi: {
    User: {
      Profile: `${Config.ExtensionApiServer}/users/profile`,
      Feature: {
        GetUsage: `${Config.ApiServer}/users/feature/usage`,
      },
    },
    TwitterUser: {
      Info: `${Config.ExtensionApiServer}/twitter_users/:userId/info`,
      Profile: `${Config.ExtensionApiServer}/twitter_users/:userId/profile`,
      Search: `${Config.ExtensionApiServer}/twitter_users/search`,
      Categories: `${Config.ExtensionApiServer}/twitter_users/categories`,
      MetricsRange: `${Config.ExtensionApiServer}/twitter_users/metrics_range`,
      CheckExist: `${Config.ExtensionApiServer}/twitter_users/check_exist`,
      Languages: `${Config.ExtensionApiServer}/twitter_users/languages`,
      Locations: `${Config.ExtensionApiServer}/twitter_users/locations`,
      SentHistorySearch: `${Config.ExtensionApiServer}/twitter_users/sent_history/search`,
    },
    Collection: {
      GetCollection: `${Config.ExtensionApiServer}/collections/:collectionId`,
      GetCollections: `${Config.ExtensionApiServer}/collections`,
      CreateCollection: `${Config.ExtensionApiServer}/collections`,
      UpdateCollection: `${Config.ExtensionApiServer}/collections/:collectionId`,
      DeleteCollection: `${Config.ExtensionApiServer}/collections/:collectionId`,
      AddCollectionUsers: `${Config.ExtensionApiServer}/collections/:collectionId/twitter_users`,
      DeleteCollectionUser: `${Config.ExtensionApiServer}/collections/:collectionId/twitter_user`,
      ShareCollection: `${Config.ExtensionApiServer}/collections/:collectionId/share`,
    },
    InfluencerCampaign: {
      GetInfluencerCampaign: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId`,
      GetInfluencerCampaigns: `${Config.ExtensionApiServer}/influencer_campaigns`,
      CreateInfluencerCampaign: `${Config.ExtensionApiServer}/influencer_campaigns`,
      UpdateInfluencerCampaign: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId`,
      UpdateInfluencerCampaignBasicInfo: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId/basic_info`,
      DeleteInfluencerCampaign: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId`,

      GetInfluencerPostLinks: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId/post_links`,
      CreateInfluencerPostLink: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId/post_links`,
      UpdateInfluencerPostLink: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId/post_links/:postLinkId`,
      DeleteInfluencerPostLink: `${Config.ExtensionApiServer}/influencer_campaigns/:campaignId/post_links/:postLinkId`,
    },
    TwitterCookie: {
      GetTwitterCookies: `${Config.ExtensionApiServer}/twitter_cookies`,
      CreateTwitterCookie: `${Config.ExtensionApiServer}/twitter_cookies`,
      CheckTwitterCookie: `${Config.ExtensionApiServer}/twitter_cookies/check`,
    },
    FeatureLimits: {
      GetFeatureLimits: `${Config.ExtensionApiServer}/features/limits`,
    },
    Public: {
      GetCollection: `${Config.ExtensionApiServer}/public/collections/:collectionPublicId`,
    },
  },
  AccountsApi: {
    User: {
      Profile: `${Config.AccountsApiServer}/users/profile`,
      Checkout: {
        Callback: `${Config.AccountsApiServer}/users/checkout/callback`,
      },
    },
    SubscriptionPlan: {
      GetSubscriptionPlans: `${Config.AccountsApiServer}/public/subscription_plans`,
      CreateSubscriptionCheckout: `${Config.AccountsApiServer}/stripe/subscription/checkout/create`,
    },
    CreditPlan: {
      GetCreditPlans: `${Config.AccountsApiServer}/public/credit_plans`,
      CreateCreditCheckout: `${Config.AccountsApiServer}/stripe/payment/checkout/create`,
    },
    Credit: {
      GetCredits: `${Config.AccountsApiServer}/users/credits/details`,
      GetCreditTransactions: `${Config.AccountsApiServer}/users/credits/transactions`,
    },
  },
  Pages: {
    Root: '/',
    User: {
      Index: '/user/',
      Info: '/user/info',
      Integration: {
        Index: '/user/integration',
        GA: '/user/integration/ga',
      },
      FileManagement: '/user/file_management',
      CampaignLink: {
        Index: '/user/campaign_link',
        Create: '/user/campaign_link/create',
      },
      Subscription: {
        Index: '/user/subscription',
      },
      Payment: {
        Index: '/user/payment',
      },
      Survey: '/survey',
      UserDisabled: '/user_disabled',
      Expired: '/expired',
    },
    WalletSelector: {
      Index: '/wallet_selector',
      CreateCohort: '/wallet_selector/cohort/create',
      CohortDetail: '/wallet_selector/:cohortId',
      WalletDetail: '/wallet_selector/wallet/:address',
    },
    AdsAudience: {
      Index: '/ads_audience',
      CreateAudience: '/ads_audience/audience/create',
      AudienceDetail: '/ads_audience/:audienceId',
    },
    InfluencerMatcher: {
      Index: '/influencer_matcher',
      CreateInfluencerCampaign: '/influencer_campaign/create',
      EditInfluencerCampaign: '/influencer_campaign/:campaignId/edit',
      CampaignSendMessage: '/influencer_campaign/:campaignId/send',
    },
    Public: {
      Collection: '/public/collection/:collectionPublicId',
    },
    FontDemo: {
      Index: '/font_demo',
    },
  },
  Public: {
    Logo: {
      Light: '/growing3-logo-light.svg',
      Dark: '/growing3-logo-dark.svg',
      NoSub: {
        Light: '/growing3-logo-light-no-sub.svg',
        Dark: '/growing3-logo-dark-no-sub.svg',
      },
    },
    Image: {
      Root: {
        WalletSelector: '/images/root/wallet-selector.png',
        AdsAudience: '/images/root/ads-audience.png',
        InfluencerMatcher: '/images/root/influencer-matcher.png',
        Changelog: '/images/root/changelog.png',
      },
      NotFound: {
        Cover: '/images/not_found/cover.png',
      },
      Coming: {
        Cover: '/images/coming/cover.png',
      },
      Header: {
        Avatar: '/images/header/avatar.png',
      },
      User: {
        Survey: '/images/user/survey.png',
      },
      WalletSelector: {
        CreateCohort: {
          NFT: '/images/wallet_selector/create_cohort/nft.png',
          Game: '/images/wallet_selector/create_cohort/game.png',
          DeFi: '/images/wallet_selector/create_cohort/defi.png',
          Filter: '/images/wallet_selector/create_cohort/filter.png',
          Category: '/images/wallet_selector/create_cohort/category.png',
        },
      },
      AdsAudience: {
        CreateAudience: '/images/ads_audience/create-audience.png',
      },
      InfluencerMatcher: {
        Loudspeaker: '/images/influencer_matcher/loudspeaker.png',
        List: '/images/influencer_matcher/list.png',
      },
      Tutorial: {
        LandingPage1: '/images/tutorial/landing-page-1.png',
        LandingPage2: '/images/tutorial/landing-page-2.png',
        LandingPage3: '/images/tutorial/landing-page-3.png',
        CreateSuccess: '/images/tutorial/create-success.png',
        CompleteFirstTask: '/images/tutorial/complete-first-task.png',
        Task1: '/images/tutorial/task-1.png',
        Task2: '/images/tutorial/task-2.png',
        AllTasksComplete: '/images/tutorial/all-tasks-complete.png',
        Reward: '/images/tutorial/reward.png',
      },
    },
    Icon: {},
    Logos: {},
  },

  External: {
    Login: `${Config.AuthServer}/login`,
    Logout: `${Config.AuthServer}/logout`,
    BookDemo:
      window.location.host === 'console.growing3.ai'
        ? 'https://growing3.ai/book_demo'
        : 'https://dev223.growing3.ai/book_demo',
    TermsOfService:
      window.location.host === 'console.growing3.ai'
        ? 'https://growing3.ai/terms-of-use'
        : 'https://dev223.growing3.ai/terms-of-use',
    PrivacyPolicy:
      window.location.host === 'console.growing3.ai'
        ? 'https://growing3.ai/privacy'
        : 'https://dev223.growing3.ai/privacy',
    Twitter: 'https://x.com',
    TwitterAds: 'https://ads.x.com',
    TwitterAdGroupEdit:
      'https://ads.x.com/campaign_form/:accountId/campaign/:campaignId/adgroup/:adGroupId/adgroup_details/edit',
    Extension: 'https://chromewebstore.google.com/detail/growing3-web3-influencer/hpcbfphmanablmeomioemmamedfffmpd',
    Telegram:
      window.location.host === 'console.growing3.ai'
        ? 'https://t.me/growing3_beta_program'
        : 'https://t.me/+xBGIjctdeMcwYjA1',
    SupportTicket: 'https://growing3.gitbook.io/growing3/influencer-matcher/report-to-add-new-influecers',
    Support:
      window.location.host === 'console.growing3.ai'
        ? 'https://growing3.ai/support'
        : 'https://dev223.growing3.ai/support',
    Changelogs: 'https://growing3.gitbook.io/growing3/about-growing3/product-change-log',
    Documentation: 'https://growing3.gitbook.io/growing3',
    GoogleSheet: 'https://docs.google.com/spreadsheets',
  },
});

const UriTitleMap = new Map<string, string>([
  [Uris.Pages.Root, 'Growing3 Console'],
  [Uris.Pages.User.Index, 'User'],
  [Uris.Pages.User.Info, 'User | Info'],
  [Uris.Pages.User.Integration.Index, 'User | Integration'],
  [Uris.Pages.User.Integration.GA, 'User | Integration | GA'],
  [Uris.Pages.User.FileManagement, 'User | File Management'],
  [Uris.Pages.User.CampaignLink.Index, 'User | Campaign Link'],
  [Uris.Pages.User.CampaignLink.Create, 'User | Create Campaign Link'],
  [Uris.Pages.User.Survey, 'User | Survey'],
  [Uris.Pages.User.Expired, 'User | Expired'],
  [Uris.Pages.User.UserDisabled, 'Growing3 Console'],
  [Uris.Pages.WalletSelector.Index, 'Wallet Selector'],
  [Uris.Pages.WalletSelector.CreateCohort, 'Wallet Selector | Create Cohort'],
  [Uris.Pages.WalletSelector.CohortDetail, 'Wallet Selector | Cohort Detail'],
  [Uris.Pages.WalletSelector.WalletDetail, 'Wallet Selector | Wallet Detail'],
  [Uris.Pages.AdsAudience.Index, 'Ads Audience'],
  [Uris.Pages.AdsAudience.CreateAudience, 'Ads Audience | Create Ads Audience'],
  [Uris.Pages.AdsAudience.AudienceDetail, 'Ads Audience | Audience Detail'],
  [Uris.Pages.InfluencerMatcher.Index, 'Influencer Matcher'],
  [Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign, 'Influencer Matcher | Create Influencer Campaign'],
  [Uris.Pages.InfluencerMatcher.EditInfluencerCampaign, 'Influencer Matcher | Edit Influencer Campaign'],
  [Uris.Pages.InfluencerMatcher.CampaignSendMessage, 'Influencer Matcher | Send Message'],
  [Uris.Pages.Public.Collection, 'Collection'],
]);

export const getUrlTitle = (pathname: string) => {
  for (const [pattern, title] of Array.from(UriTitleMap.entries())) {
    const regexPattern = pattern
      .replace(/:\w+/g, '[\\d\\w-]+') // Match digits, words, and hyphens
      .replace(/\//g, '\\/') // Escape forward slashes
      .replace(/\./g, '\\.'); // Escape dots
    const regex = new RegExp(`^${regexPattern}$`);
    if (regex.test(pathname)) {
      return title;
    }
  }
  return undefined; // Return undefined if no match is found
};
